import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { Button, Typography, ThemeProvider } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Wordmark from './Wordmark';
import PasswordField from './PasswordField';
import { loginFormTheme, withFormStyles } from './login/styles';
import { routes } from '../common/routes';
import { requestPasswordReset, validatePasswordResetToken } from '../api/user';

export const emailErrorMessage = 'Please enter a valid email address';

const Container = styled.div`
  ${withFormStyles()}
`;

const Join: React.FC = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isValidToken, setIsValidToken] = useState(false);
  const [showAlertMessage, setShowAlertMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const history = useHistory();
  const { token } = useParams();

  useEffect(() => {
    const validateToken: () => void = async () => {
      const response = await validatePasswordResetToken(token);
      if (response === 200) {
        setIsValidToken(true);
      }
    };

    if (token) {
      validateToken();
    }
  }, [token]);

  const handleRequestPasswordReset: () => void = async () => {
    const response = await requestPasswordReset(newPassword, token);
    if (response === 204) {
      createNotification('Password updated');
      setTimeout(() => history.push(routes.login), 2500);
    } else if (response === 400) {
      createNotification(
        'Please fill in New Password and Confirm Password fields',
      );
    } else if (response === 404) {
      createNotification('Invalid Reset Password token');
    }
  };

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.preventDefault();
    e.stopPropagation();

    if (isValidToken) {
      handleRequestPasswordReset();
    }
  };

  const createNotification = (message: string) => {
    setAlertMessage(message);
    setShowAlertMessage(true);
  };

  const removeNotification = () => {
    setShowAlertMessage(false);
    setAlertMessage('');
  };

  return (
    <ThemeProvider theme={loginFormTheme}>
      <CssBaseline>
        <Container>
          <Typography variant="h1">Run AMZ</Typography>
          <Wordmark />
          <form>
            {isValidToken ? (
              <>
                <PasswordField
                  fieldKey="newPassword"
                  id="new-password"
                  label="New Password"
                  value={newPassword}
                  onChange={(key, value) => setNewPassword(value)}
                />
                <PasswordField
                  fieldKey="confirmPassword"
                  id="confirm-password"
                  label="Confirm Password"
                  value={confirmPassword}
                  onChange={(key, value) => setConfirmPassword(value)}
                />
                <Button
                  disabled={
                    newPassword === '' ||
                    confirmPassword === '' ||
                    newPassword !== confirmPassword
                  }
                  disableRipple
                  onClick={handleButtonClick}
                  type="submit"
                  variant="outlined"
                >
                  Submit
                </Button>
              </>
            ) : (
              <Typography variant="body1" className="info">
                <span className="emphasize">Don&apos;t have an account?</span>
                <span>Reach out to your client success manager at Run AMZ</span>
              </Typography>
            )}
          </form>
          <Snackbar
            open={showAlertMessage}
            autoHideDuration={6000}
            onClose={removeNotification}
          >
            <MuiAlert
              elevation={6}
              variant="filled"
              onClose={removeNotification}
              severity="success"
            >
              {alertMessage}
            </MuiAlert>
          </Snackbar>
        </Container>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default Join;
