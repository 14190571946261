import React, { useState } from 'react';
import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  Button,
  TextField,
  Typography,
  ThemeProvider,
} from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Link from '@material-ui/core/Link';
import { useAuth } from '../../auth/useAuth';
import { login } from '../../api/user';
import { routes } from '../../common/routes';
import { loginFormTheme, withFormStyles } from './styles';
import Wordmark from '../Wordmark';

const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const emailErrorMessage = 'Please enter a valid email address';

const Container = styled.div`
  ${withFormStyles()}
`;

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');

  const { setToken, setRefreshAuthToken } = useAuth();

  const search = useLocation().search;

  const history = useHistory();

  const handleEmailFieldBlur: React.FocusEventHandler<HTMLInputElement> = e => {
    if (e.target.value === '') {
      setEmailError('');
    } else if (!emailRegex.test(e.target.value)) {
      setEmailError(emailErrorMessage);
    } else {
      setEmailError('');
    }
  };

  const handleButtonClick: React.MouseEventHandler<HTMLButtonElement> = async e => {
    e.preventDefault();
    e.stopPropagation();

    const response = await login(email.toLowerCase(), password);
    if (response.token && response.refresh_token) {
      setToken(response.token);
      setRefreshAuthToken(response.refresh_token);
      history.push(`${routes.dashboard.sales}${search}`);
    } else if (response.code === 401 && response.message) {
      setPasswordError(response.message);
    }
  };

  return (
    <ThemeProvider theme={loginFormTheme}>
      <CssBaseline>
        <Container>
          <Typography variant="h1">Run AMZ</Typography>
          <Wordmark />
          <form>
            <TextField
              error={emailError !== ''}
              helperText={emailError}
              inputProps={{ 'aria-label': 'Email' }}
              label="Email"
              onBlur={handleEmailFieldBlur}
              onChange={e => setEmail(e.target.value)}
              required
              value={email}
              variant="outlined"
            />
            <TextField
              error={passwordError !== ''}
              helperText={passwordError}
              inputProps={{ 'aria-label': 'Password' }}
              label="Password"
              onChange={e => setPassword(e.target.value)}
              required
              type="password"
              value={password}
              variant="outlined"
            />
            <Button
              disableRipple
              onClick={handleButtonClick}
              type="submit"
              variant="outlined"
            >
              Login
            </Button>
            <Typography variant="body1">
              <Link
                component={RouterLink}
                to={routes.resetPassword.replace(':token?', '')}
                underline="none"
              >
                Forgot Password
              </Link>
            </Typography>
            <Typography variant="body1" className="info">
              <a
                href="https://runamz.com/contact/"
                style={{ color: 'inherit', textDecoration: 'none' }}
              >
                <span className="emphasize">Don&apos;t have an account?</span>
              </a>
              <span>Reach out to your client success manager at Run AMZ</span>
            </Typography>
          </form>
        </Container>
      </CssBaseline>
    </ThemeProvider>
  );
};

export default Login;
